//REACT IMPORT
import React from "react";
//LIBRARY IMPORT
import PropTypes from "prop-types";

/**
 * Component that renders a welcome message with the name of the user.
 * @prop {string} data - The name of the user.
 * @returns {ReactElement}
*/
const Welcome = ({data}) => {
    //RENDER
    return (
      <h2 className="green-text mt-5" >Bienvenue {data} </h2>
    )
  }

  //PROPTYPES
  Welcome.propTypes = {
    data: PropTypes.string
};

//EXPORT
export default Welcome;