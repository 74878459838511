import React from "react";
import { Label } from "reactstrap";
import MaterialTable from "material-table";

/**
 * A reusable table component with a white background, no border, and a
 * separate border collapse. The columns are customizable with a title,
 * columns, data, actions, and onRowClick function. The onRowClick function
 * is called when a row is clicked.
 *
 * @param {Object} props Component props.
 * @param {string} props.title Title of the table.
 * @param {Object[]} props.columns Column definitions.
 * @param {Object[]} props.data Table data.
 * @param {Object[]} props.actions Actions to show in the actions column.
 * @param {function} props.onRowClick Function to be called when a row is clicked.
 * @return {JSX.Element} The rendered table component.
 */
const ClickableTable = ({ data, title, columns, actions, onRowClick } ) => {

    return (
        <MaterialTable
            style={{
                backgroundColor: "white",
                border: "none ",
                boxShadow: "none",
                borderCollapse: "separate",
                borderSpacing: "0 1rem",
                width: "100%",
            }}
            title={<Label
                style={{
                    fontSize: "1.8rem",
                    color: "#00A359",
                    fontWeight: "400",
                    lineHeight: "25px",
                    marginBottom: "2rem", fontFamily: 'sans-serif'
                }}>{title}</Label>}
            columns={columns}
            data={data}
            actions={actions}
            localization={{
                body: {
                    emptyDataSourceMessage: 'Aucune donnée à afficher',
                },
                pagination: {
                    labelDisplayedRows: '{from}-{to} de {count}',
                    labelRowsSelect: 'lignes',
                    labelRowsPerPage: 'lignes par page:',
                    firstAriaLabel: 'Première page',
                    firstTooltip: 'Première page',
                    previousAriaLabel: 'Page précédente',
                    previousTooltip: 'Page précédente',
                    nextAriaLabel: 'Page suivante',
                    nextTooltip: 'Page suivante',
                    lastAriaLabel: 'Dernière page',
                    lastTooltip: 'Dernière page'
                },
            }}
            onRowClick={onRowClick}
            options={{
                pageSize: 5,
                pageSizeOptions: [5, 10, 15],
                actionsColumnIndex: -1,
                cellStyle: {
                    lineHeight: "1 ",
                    textAlign: "start",
                },
                headerStyle: {
                    lineHeight: "1 ",
                    textAlign: "start",
                    backgroundColor: "#f3fbf7",
                },
                searchFieldStyle: {
                    color: "#00a359",
                    MuiInputUnderline: "none",
                },
            }}
        />
    );
};



export default ClickableTable;
