import jwtDecode from "jwt-decode";
import http from "./httpServices";
import { apiUrl } from "./../config.json";
import axios from "axios";

const apiEndpoint = apiUrl + "/api/login_check";
const apiModifPassword = apiUrl + "/api/user/new_password";
const apiConfirmClientAccount = apiUrl +'/api/clients/confirm/client/account'
const tokenKey = "token";

http.setJwt(getJwt());

export async function loginUser(username, password) {
  const user = await axios.post(apiEndpoint, { username, password });
  return user;
}

export async function forgetPassword(email) {
  const user = await axios.post(apiModifPassword, { email });
  return user;
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    const userDecoded = jwtDecode(jwt);
    return userDecoded
  } catch (ex) {
    return null;
  }
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

export function getJwt() {
  localStorage.getItem(tokenKey);
}

export const confirmClientAccount = async (token) => {
  try {
    const response = await axios.get(`${apiConfirmClientAccount}`, {
      params: { token: token },
    });
    return response;
  } catch (error) {
    console.error('Error confirming account:', error);
    throw error;
  }
};

const authServices = {
  loginUser,
  loginWithJwt,
  logout,
  getJwt,
  forgetPassword,
  confirmClientAccount
};

export default authServices;