import axios from "axios";
import { toast } from "react-toastify";


axios.interceptors.response.use(
  (response) => {
    // Si la réponse a un statut 200 et un code spécifique 331 => session expire
    if (response.status === 200) {
      if (response.data?.header?.code === 331) {
        toast.error("Session expired. Please login again.");
        setTimeout(() => {
          localStorage.removeItem("token");
          window.location.href = "/login";
        },1000)
      }
    }

    return response;
  },
  (error) => {
    // Si la requête a un statut different de 200
    if (error.response) {
      const status = error.response.status;

      if (status === 404) {
        toast.error("Data not found.");
      } else if (status === 403) {
        toast.error("Forbidden error: " + error.response.data.message);
      }else if (status === 401) {
        if (error.response.data.message === "Invalid JWT Token" || error.response.data.message === "Expired JWT Token") {
          if(localStorage.getItem("token") !==''){
            toast.error("Token expired. Please login again.");
            setTimeout(() => {
              localStorage.removeItem("token");
              window.location.href = "/login";
            },1000)
          }
        }
      } else {
        toast.error("An unexpected error occurred.");
      }
    } else if (error.request) {
      toast.error("No response received from the server.");
    } else {
      toast.error("Error setting up the request.");
    }

    return Promise.reject(error);
  }
);


/**
 * Set the Authorization header for the axios instance to the given JWT.
 * This method is used to set the JWT once the user logs in.
*/
function setJwt(jwt) { 
  axios.defaults.headers.common["Authorization"] = jwt; 
} 


const httpServices = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt
};

//Export the axios instance
export default httpServices;