import React, { useState } from 'react'
import RapportHeader from './RapportHeader'
import RapportTable from './RapportTable'
import PropTypes from 'prop-types'
import { formatNumber, formatTime } from '../../../../../utils/helpers'
import LoaderButton from '../../../../common/loaders/LoaderButton'
import { generateRecommendationPDF } from '../../../admin/helpers/generatePDF'

/**
 * A React functional component that renders a report of a scenario
 * 
 * @prop {Object} data - An object containing the answers and scores of the scenario
 * 
 * @returns {JSX.Element} A div containing sections for the recommandations, the scores and the video link
*/
const Report = ({ data }) => {

    //HOOKS
    const [loadingPDF, setLoadingPDF] = useState(false);
    //FUNCTIONS
    
    /**
     * Initiates the download of a recommendation PDF report for the client.
     * Sets the loading state to true during the report generation process.
     * Utilizes the `generateRecommendationPDF` helper function with the client's name.
     * Catches and logs any errors that occur during PDF generation.
     * Resets the loading state to false once the process is complete.
     * Only executes if `data` is available.
    */
    const handleDownloadRapport = async () => {
        if(data) {
            try {
                setLoadingPDF(true);
                await generateRecommendationPDF(data?.step_details?.scenario_name);
                setLoadingPDF(false);
            } catch (error) {
                console.log(error);
            }
        }
    };

    /**
     * Renders a section for each feedback in the most_remarquable_feedback array in the data object.
     * 
     * @returns {JSX.Element | null} A fragment containing sections for each feedback if the data is valid, else null
    */
    const feedBackSection = () => {
        if (!data?.most_remarquable_feedback || !Array.isArray(data.most_remarquable_feedback)) {
            return null;
        }
        console.log("Data",data.most_remarquable_feedback);
        
        return data.most_remarquable_feedback.map((constat, index) => (
            <div className="rapport-constat" key={index}>
                <div className="rapport-constat-title">
                    Constat {index + 1}
                </div>
                <div className="rapport-constat-content">
                    <div className="rapport-constat-response">
                        {constat[1] || "Aucune réponse"}
                    </div>
                    <div className="rapport-constat-answer">
                        {constat[2] || "Aucune observation"} 
                    </div>
                </div>
            </div>
        ));
    };

    //RENDER
    return (
        <>
            <div className='rapport-content' id="pdf-content">
                <div className='rapport-header'>
                    <RapportHeader label="Scenario" value={data?.step_details?.scenario_name} color="color-white" />
                    <RapportHeader label={`Etape ${data?.step}`} value={data?.step_details?.question} color="color-blue" />
                </div>
                <div className='rapport-recommandations'>
                    <div className='rapport-recommandation'>
                        <div className='rapport-recommandation-content'>
                            <div className='rapport-section-title'>
                                Analyse des réponses et des scores :  
                            </div>
                            <div className='rapport-section-subtitle'>
                                Bilan des réactions
                            </div>
                            <div className='rapport-recommandation-text'>
                                <p className='mt-3'>
                                    {data?.bilan_redaction}
                                </p>
                            </div>
                        </div>
                        <div className='rapport-score bg-colors-wihte'>
                            <div className='rapport-score-title '>
                                Nb de Réponses
                            </div>
                            <div className='rapport-score-value'>
                                {data?.answers?.length}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='rapport-recommandation'>
                            <div className='rapport-recommandation-content'>
                                <div className='rapport-section-subtitle'>
                                    Analyses des Scores
                                </div>
                                <div className='rapport-recommandation-text'>
                                    <p className='mt-3'>
                                        {data?.analyse_score}
                                    </p>
                                </div>
                                <div className='retours-container'>
                                    <div className='rapport-section-subtitle'>
                                            Retours les plus marquants
                                    </div>
                                    <div className='rapport-constats'>
                                        {feedBackSection()}
                                    </div>
                                </div>
                            </div>
                            <div className='rapport-score bg-colors-blue'>
                                <div className='rapport-score-title'>
                                    Score Moyen
                                </div>
                                <div className='rapport-score-value'>
                                    <span className={data?.average_score >= 0 ? "green-text": "red-text"}>{formatNumber(data?.average_score)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='rapport-recommandation'>
                        <div className='rapport-recommandation-content'>
                            <div className='rapport-section-subtitle'>
                                Liste des Réponses
                            </div>
                            <RapportTable  data={data?.answers} />                       
                        </div>
                        <div className='rapport-score bg-colors-wihte'>
                            <div className='rapport-score-title'>
                                Durée Moyenne
                            </div>
                            <div className='rapport-score-value'>
                                <span>{formatTime(data?.average_duration)}</span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='rapport-section-title'>
                        Lien Vidéo
                        </div>
                    </div>
                </div>
            </div>
            <LoaderButton className="btn-success my-5" loading={loadingPDF} name="Télècharger PDF" onClick={handleDownloadRapport} />
        </>
    )
}


//PROTYPES
Report.propTypes = {
    data: PropTypes.object.isRequired
}

//EXPORT
export default Report;
