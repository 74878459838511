//REACT IMPORTS
import React, { useCallback, useState } from "react";
//LIBRARIES IMPORTS
import { Label } from "reactstrap";
import { toast } from "react-toastify";
//COMPONENTS IMPORTS
import SkeletonHeader from "../../analyzes/byStep/skeletonHeader.jsx";
import HeaderScoreModal from "./HeaderScoreModal.jsx";
//ASSETS IMPORTS
import Eye from '../../../assets/eye.svg';
import Download from "../../../assets/dwnld.png";
//SERVICES IMPORTS
import { downloadExcel } from "../../../services/statServices.js";
import PropTypes from "prop-types";

/**
 * Renders the ScenarioDetails component which displays various
 * details about a scenario, including steps, testers, panel type,
 * score, and duration. It conditionally renders modals and download
 * buttons based on the provided renderType.
 *
 * @param {Object} skeleton - The skeleton object for loading states.
 * @param {Object} header - The header object containing scenario details.
 * @param {number} nbrTester - The number of testers.
 * @param {Object} analyseObj - Object containing analysis data.
 * @param {boolean} excel - Flag indicating if Excel download is available.
 * @param {Array} arrayOftesters - Array of testers for the scenario.
 * @param {string} renderType - Determines the type of rendering (e.g., 'analyse' or 'allData').
 * @returns {JSX.Element} The rendered ScenarioDetails component.
 */
const ScenarioDetails = ({ skeleton, header, nbrTester, analyseObj, excel, arrayOftesters, renderType }) => {

    /* HOOKS */
    const [open, setOpen] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    /* FUNCTIONS */

    /**
     * Handles the opening of the modal.
    */
    const handleOpenModal = useCallback(() => {
        setOpen((prevState) => !prevState);
    }, [setOpen]);

    /**
     * Download all data in excel format
     * @returns {Promise<void>}
     */
    const handleDownloadAllData = useCallback(async () => {
        let url;
        try {
            setIsDownloading(true);
            if(!arrayOftesters.length ){
                toast.error("Excel non disponible");
                return;
            }
            const res = await downloadExcel(header.id, arrayOftesters);            
            if (res?.header?.code === 400) {
                toast.error(res?.header?.message);
                return;
            }

            url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `allData_${header?.title || "data"}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toast.success("Fichier téléchargé avec succès");
        } catch (error) {
            console.error("ERROR", error);
        } finally {
            setIsDownloading(false);
            URL.revokeObjectURL(url);
        }
    }, [header, arrayOftesters]);    
    
    /**
     * Renders the header based on the renderType prop.
     * If renderType is 'analyse', renders the score modal and the download button.
     * If renderType is 'allData', renders the download button.
     * If renderType is neither of the above, returns null.
     * @return {JSX.Element} The JSX element representing the header component.
     */
    const onHeaderRender = useCallback(() => {    
        if(renderType === 'analyse'){ 
            return(
                <>
                    <div className='anaylyse-etape-img'>
                    <Label className="header_label">Résumé</Label>
                    {
                        skeleton ?  <SkeletonHeader /> : header?.score ?
                        <img src={Eye} alt="2M-advisory" style={{ width: '35px', cursor: 'pointer' }} onClick={handleOpenModal} />
                        : 
                        null
                    }
                    </div>
                    {
                        open &&  <HeaderScoreModal open={open} handleOpenModal={handleOpenModal} header={header} analyseObj={analyseObj} />
                    }
                </>
            )
        }else if(renderType === 'allData'){
            return(
                <>
                {
                    excel && (
                        <div className='anaylyse-etape'>
                            <Label className="header_label">Télécharger</Label>
                            {
                                skeleton ?  
                                    <SkeletonHeader /> 
                                : 
                                    header?.testers &&
                                        <span className='header_span'>
                                            {
                                                isDownloading ? 
                                                <i className="fa fa-spinner fa-pulse" aria-hidden="true"></i>
                                                : 
                                                <img src={Download} alt="" style={{ width: "1.5rem", cursor: "pointer" }} onClick={handleDownloadAllData} />
                                            }
                                        </span>
                            }
                        </div>
                    )
                }
                </>
            )
        }else{
            return null
        }
    }, [renderType, skeleton, header, open, handleOpenModal, analyseObj, excel, handleDownloadAllData, isDownloading]);


    
    //RENDER
    return(
        <div className='anaylyse_header-etapes'>
            <div className='anaylyse-etape'>
                <Label className="header_label">Etapes</Label>
                {
                    skeleton ? <SkeletonHeader /> : header?.steps ? <span className='header_span'>{header?.steps} </span> : null
                }
            </div>
            <div className='anaylyse-etape'>
                <Label className="header_label">Testeurs</Label>
                {
                    skeleton ? 
                        <SkeletonHeader /> 
                    : 
                        header?.testers ? 
                            <>
                                {
                                    nbrTester === header?.testers ?
                                        <span className='header_span completTest'>
                                            {`${nbrTester}/${header?.testers}`}
                                        </span>
                                    :
                                        <span className='header_span encoursTest'>
                                            {`${nbrTester}/${header?.testers}`}
                                        </span>
                                }
                            </>
                        :
                            ""
                }
            </div>
            <div className='anaylyse-etape'>
                <Label className="header_label">Panel</Label>
                {
                    skeleton ? <SkeletonHeader /> : header?.type ? <span className='header_span'>{header?.type === 'client' ? 'Client' : header?.type}</span> : null
                }
            </div>
            <div className='anaylyse-etape'>
                <Label className="header_label" data-tip='Le score peut aller de -1 à 1'>Score</Label>
                {
                    skeleton ? <SkeletonHeader /> : header?.score ? 
                            <span className='header_span' style={{ color: header?.score >= 0 ? 'green' : 'red' }}>{header?.score}</span>
                        : 
                            null
                }
            </div>
            <div className='anaylyse-etape'>
                <Label className="header_label">Durée</Label>
                {
                    skeleton ? 
                        <SkeletonHeader />  
                    : 
                        header?.duration ? 
                            <span className='header_span'>
                                {`${Math.floor(header?.duration / 60) > 0 ? Math.floor(header?.duration / 60) + 'min :' : ''} ${Math.floor(header?.duration) % 60} sec`}
                            </span> 
                        : 
                            null
                }
            </div>
            {onHeaderRender()}
        </div>
    );
}

//PROPTYPES
ScenarioDetails.propTypes = {
    header: PropTypes.object,
    nbrTester: PropTypes.number,
    scenarios: PropTypes.array,
    onChangeScenario: PropTypes.func,
    renderType: PropTypes.string,
};

//EXPORT
export default ScenarioDetails;