//REACT IMPORT
import React, { useCallback, useEffect, useState } from 'react'
//REDUX IMPORT
import { useSelector } from 'react-redux';
//LIBRARY IMPORT
import { toast } from 'react-toastify';
//STYLES IMPORT
import './rapport.css';
//SERVICES IMPORT
import { getScenarioDetails, getScenarioReport } from '../../../../services/statServices';
//COMPONENTS IMPORT
import DashboardHeader from '../../../common/DashboardHeader';
import HeaderBar from '../../../common/header/HeaderBar';
import Report from './components/Report';
import SpinnerLoader from '../../../common/loaders/SpinnerLoader';
import NoDataSelected from '../../../common/NoDataSelected';

const RapportRecommandations = () => {
    //HOOKS
    const {data, selectedScenario} = useSelector((state) => state.scenario);
    const [loading, setLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(true);
    const [headerPayload, setHeaderPayload] = useState({});
    const [_id, setId] = useState(0);
    const [scenariosResponse, setScenariosResponse] = useState([]);


    //FUNCTIONS
    const onChangeScenario = useCallback((e) => {    
        if (isNaN(e)) {
            setId(e.target.value);
        } else {
            setId(+e);
        }
    }, []);


    const getScenarioData = useCallback(async () => {
        try {
            setLoading(true)            
            if (_id) {
                const scenarioDetails = await getScenarioDetails(_id);
                if(scenarioDetails?.header.code !== 200){
                    return toast.error(scenarioDetails?.header.message)
                }else {
                    setHeaderPayload(scenarioDetails?.response);
                    setIsEmpty(false);
                    const scenarioReport = await getScenarioReport(scenarioDetails?.response.id);
                    if(scenarioReport?.header.code !== 200){
                        return toast.error(scenarioReport?.header.message)
                    }else{
                        let data = scenarioReport?.response;
                        if (!data || !Array.isArray(data)) {
                            return null;
                        }
                        setScenariosResponse(data[0]);                        
                    }
                }
            }
            setLoading(false); 
        } catch (error) {
            console.log("Error",error)
        }
    }, [_id]);


    useEffect(() => {
        try{
            if (selectedScenario?.id !== null && selectedScenario?.id !== undefined) {
            setId(selectedScenario.id);
            onChangeScenario(selectedScenario.id);
            }
        }catch(error){
            console.log("Error loading scenario", error);
        }
    }, [selectedScenario, onChangeScenario]);


    useEffect(() => {
        try{
            getScenarioData();
        }catch(error){
            console.log("Error loading scenario", error);
        }
    }, [getScenarioData]);


    //RENDER
    return (
        <div className='rapport-container'>
            <DashboardHeader>
                <HeaderBar
                    title="Synthèse des recommandations"
                    header={headerPayload}
                    nbrTester={headerPayload?.testers}
                    scenarios={data}
                    onChangeScenario={onChangeScenario}
                    renderType="allData"
                    skeleton={loading}
                />
            </DashboardHeader>
            {
                !isEmpty?(
                <>
                    {
                        loading ? (
                            <div className="allDataResponseLoadersContainer">
                                <SpinnerLoader />
                            </div>
                        ):(
                            <div className="mb-5">
                                <Report data={scenariosResponse} />
                            </div>
                        )
                    }
                </>
                ):(
                    <NoDataSelected title="Veuillez sélectionner un scénario." />
                )
            }
        </div>
    )
}

//Export
export default RapportRecommandations;
