export const EMAIL_CHANGED = "EMAIL_CHANGED";
export const PASSWORD_CHANGED = "PASSWORD_CHANGED";
export const LOGOUT = "LOGOUT";
export const FAILED_LOGIN = "FAILED_LOGIN";
export const SUCCESS_LOGIN = "SUCCESS_LOGIN";
export const LNG_CHANGED = "LNG_CHANGED";
export const SUCCESS_LOGIN_TESTER2 = "SUCCESS_LOGIN_TESTER2";
export const SUCCESS_LOGIN_TESTER1 = "SUCCESS_LOGIN_TESTER1";
export const SUCCESS_OPEN_WINDOW = "SUCCESS_OPEN_WINDOW";
export const SUCCESS_OPEN_POPUP = "SUCCESS_OPEN_POPUP";
export const FAILED_FORGET_PASSWORD = "FAILED_FORGET_PASSWORD";
export const SUCCESS_FORGET_PASSWORD = "SUCCESS_FORGET_PASSWORD";
