export const SUCCESS_GET_SCENARIOS = "SUCCESS_GET_SCENARIOS";
export const FAILED_GET_SCENARIOS = "FAILED_GET_SCENARIOS";
export const SUCCESS_GET_PANELS = "SUCCESS_GET_PANELS";
export const FAILED_GET_PANELS = "FAILED_GET_PANELS";
export const SUCCESS_GET_COUNT = "SUCCESS_GET_COUNT";
export const FAILED_GET_COUNT = "FAILED_GET_COUNT";
export const SAVE_LIST = "SAVE_LIST";
export const DELETE_LIST = "DELETE_LIST";
export const UPDATE_LIST = "UPDATE_LIST";
export const SELECTED_SCENARIO = "SELECTED_SCENARIO"
export const SELECTED_PANEL = "SELECTED_PANEL"
export const CLEAN_SELECTED_PANEL = "CLEAN_SELECTED_PANEL"