import http from "./httpServices";
import { apiUrl } from "./../config.json";
import axios from "axios";

const apiEndpoint = apiUrl;
const tokenKey = "token";

http.setJwt(getJwt());



export const getScenarioDetails = async (id) => {
    const stat = await axios.get(apiEndpoint + `/api/scenario/details`, {
        params: { id: +id },
        headers: getAuthorization()
    })
    const { data } = stat
    return data;
}
export const getStepStats = async (id) => {
    const stat = await axios.get(apiEndpoint + `/api/stats/step`, {
        params: { id: +id },
        headers: getAuthorization()
    })
    const { data } = stat
    return data;
}
export const getStatsByTester = async (tester_id, scenario_id) => {
    const stat = await axios.get(apiEndpoint + `/api/stats/tester/scenario`,  {
        params: { tester_id: +tester_id, scenario_id: +scenario_id },
        headers: getAuthorization()
    })
    const { data } = stat
    return data;
}

export const getScenarioStat = async (id) => {
    const response = await axios.get(apiEndpoint + `/api/stats/scenario`, {
        headers: getAuthorization(),
        params: { scenario_id: +id },
    });
    const { data } = response;
    return data;
}

export const getStepResponseDetails = async (tester_id, answer_id) => {
    const responseDetails = await axios.get(apiEndpoint + `/api/stats/tester/step/video`, {
        params : { tester_id, answer_id },
        headers: getAuthorization()
    })
    const { data } = responseDetails;
    return data;
}

export const getScenarioReport = async (id) => {
    const response = await axios.get(apiEndpoint + `/api/openai/open/a/i/targeted/recommendations/step`, {
        headers: getAuthorization(),
        params: { scenario_id: +id },
    });
    const { data } = response;
    return data;
}



export const getStepFaceshotsDetails = async (tester_id, answer_id) => {
    const responseDetails = await axios.get(apiEndpoint + `/api/facerecognition/step/details/emotions`, {
        params : { tester_id: tester_id, answer_id: answer_id},
        headers: getAuthorization()
    })
    const { data } = responseDetails;
    return data;
}

export async function downloadExcel(scenario_id, testers_id) {
    const res = await http.post(apiEndpoint + "/api/scenario/download/file/xlsx", {scenario_id, testers_id}, {
        headers: getAuthorization(),
        responseType: 'blob',
        timeout: 100000
    });

    const { data } = res;
    return data;
}

export function getJwt() {
    return localStorage.getItem(tokenKey);
}

function getAuthorization() {
    return { Authorization: `Bearer ${getJwt()}` };
}