export const SUCCESS_SAVE_TESTER = "SUCCESS_SAVE_TESTER";
export const FAILED_SAVE_TESTER = "FAILED_SAVE_TESTER";
export const SUCCESS_SAVE_CLIENT = "SUCCESS_SAVE_CLIENT";
export const FAILED_SAVE_CLIENT = "FAILED_SAVE_CLIENT";
export const SUCCESS_GET_USER_CONNECTED = "SUCCESS_GET_USER_CONNECTED";
export const FAILED_GET_USER_CONNECTED = "FAILED_GET_USER_CONNECTED";
export const SUCCESS_GET_CLIENTS = "SUCCESS_GET_CLIENTS";
export const FAILED_GET_CLIENTS = "FAILED_GET_CLIENTS";
export const SUCCESS_GET_TESTERS = "SUCCESS_GET_TESTERS";
export const FAILED_GET_TESTERS = "FAILED_GET_TESTERS";
export const FAILED_GET_SUBCLIENTS = "FAILED_GET_SUBCLIENTS";
export const SUCCESS_GET_SUBCLIENTS = "SUCCESS_GET_SUBCLIENTS";
export const FAILED_SAVE_SUBCLIENT = "FAILED_SAVE_SUBCLIENT";
export const SUCCESS_GET_CURRENT_USER = "SUCCESS_GET_CURRENT_USER";
export const FAILED_GET_CURRENT_USER = "FAILED_GET_CURRENT_USER";

    